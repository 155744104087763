<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="8" class="pr-2">
        <v-card flat>
          <v-card-title class="secondary">
            <span>Custom fields</span>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="green" v-on="on" dark @click="addCustomField">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <span>Add custom field</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="customFields"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              single-select
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">{{ item.default }}</td>
                    <td class="justify-center layout px-0">
                      <v-btn icon class="mx-0" @click="onEditCustomField(item)">
                        <v-icon color="green darken-3">edit</v-icon>
                      </v-btn>
                      <v-btn icon class="mx-0" @click="toggleDeleteCustomFieldDialog(item)">
                        <v-icon color="green darken-3">delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template slot="no-data">
                <v-btn color="primary">Reset</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-pagination
              v-if="customFields.length >= 10"
              class="mt-3"
              light
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- <v-col cols="4" >
        <v-card flat>
          <v-card-title class="secondary">
            <span>Add Custom field</span>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="customFields"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              single-select
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">{{ item.default }}</td>
                  </tr>
                </tbody>
              </template>
              <template slot="no-data">
                <v-btn color="primary">Reset</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-pagination
              v-if="customFields.length >= 10"
              class="mt-3"
              light
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
    <v-dialog v-model="openDeleteCustomFieldDialog" persistent max-width="600">
      <v-card>
        <v-card-title
          class="headline"
        >Are you sure you want to proceed further ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="onDeleteCustomField">Yes, Delete</v-btn>
          <v-btn color="green darken-1" text @click="openDeleteCustomFieldDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "manage-custom-fields",
  components: {},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      searchMeter: "",
      openDeleteCustomFieldDialog: false,
      selectedCustomField: {},
      filter: "",
      headers: [
        {
          text: "Name",
          align: "center",
          sortable: true,
          value: "name"
        },
        { text: "Default value", value: "default", align: "center" },
         { text: "Actions", value: "", align: "center" }
      ]
    };
  },
  computed: {
    customFields() {
      return this.$store.getters["MDMStore/getterOrgCustomFields"];
    },
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getOrgCustomFields",
      orgId: localStorage.getItem("orgId")
    });
  },
  methods: {
    onEditCustomField(item){
      this.$router.push({ name: "editcustomfield", params: item})
    },
    addCustomField(){
      this.$router.push({ name: "newcustomfield", params: {}})
    },
    toggleDeleteCustomFieldDialog(custField) {
      this.selectedCustomField = custField;
      this.openDeleteCustomFieldDialog = true;
    },
    onDeleteCustomField() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/deleteCustomField",
          custFieldId: self.selectedCustomField.id
        })
        .then(result => {
          if (result.data.hasOwnProperty("success") && result.data.success) {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Custom field deleted successfully",
              status: "success"
            });
            self.$store.dispatch({
              type: "MDMStore/getOrgCustomFields",
              orgId: localStorage.getItem("orgId")
            });
          } else {
            self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {
              text: "Error in deleting custom field.",
              status: "warning"
            });
          }
          self.openDeleteCustomFieldDialog = false;
        });
    }
  }
};
</script>
<style scoped>
</style>

  